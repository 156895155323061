<template>
    <!--Invoice-->
    <div class="invoice card shadow-sm p-3">
        <div class="card-header bg-transparent">
            <div class="row no-gutters">
                <div class="col-lg-6">
                    <h3>#Invoice</h3>
                </div>
                <div class="col-lg-6 text-right">
                    <router-link to="/dashboard" class="btn btn-success shadow-none"><i class="mdi mdi-chevron-left pr-1"></i> Back to order list</router-link>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <!-- <div class="invoice-download text-right pb-3">
                        <button type="button" class="btn btn-success shadow-none"><i class="mdi mdi-cloud-download-outline pr-1"></i> Download</button>
                    </div> -->
                    <div class="table-responsive">
                        <table class="table table-borderless border">
                            <thead class="border-bottom">
                                <tr>
                                    <td>
                                        <h3>
                                             <slot v-if="$store.state.appInfos!=null">
                                                <span >{{this.$store.state.appInfos.name}}</span>
                                              </slot>
                                              <slot v-else>
                                                <span >Makeup World BD</span>
                                            </slot>
                                        </h3>
                                    </td>
                                    <td align="right">
                                        <img :src="barcode" alt="Makeup World BD">
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                 <!--   <pre>{{$store.state.orderDetails}}</pre> -->
                                <tr>
                                    <td colspan="2" height="50"></td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Invoice No:</strong>
                                        <slot v-if="$store.state.orderDetails!=null">
                                            <slot v-if="$store.state.orderDetails.msgFlag==true">
                                                <slot v-if="$store.state.orderDetails.dataInfos">
                                                    #MWBD-{{$store.state.orderDetails.dataInfos.id}}
                                                </slot>
                                            </slot>
                                        </slot>
                                    </td>
                                    <td rowspan="4" style="vertical-align: top;" align="right">
                                       <strong>Date:</strong> <slot v-if="$store.state.orderDetails!=null">
                                            <slot v-if="$store.state.orderDetails.msgFlag==true">
                                                <slot v-if="$store.state.orderDetails.dataInfos">
                                                    {{$store.state.orderDetails.dataInfos.created_at | formatDateTime}}
                                                </slot>
                                            </slot>
                                        </slot>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Name:</strong>
                                        <slot v-if="$store.state.orderDetails!=null">
                                            <slot v-if="$store.state.orderDetails.msgFlag==true">
                                                <slot v-if="$store.state.orderDetails.dataInfos.customer_address!=null">
                                                    {{$store.state.orderDetails.dataInfos.customer_address.name}}
                                                </slot>
                                            </slot>
                                        </slot>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Phone:</strong>
                                        <slot v-if="$store.state.orderDetails!=null">
                                            <slot v-if="$store.state.orderDetails.msgFlag==true">
                                                <slot v-if="$store.state.orderDetails.dataInfos.customer_address!=null">
                                                    {{$store.state.orderDetails.dataInfos.customer_address.phone}}
                                                </slot>
                                            </slot>
                                        </slot>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Address:</strong>
                                        <slot v-if="$store.state.orderDetails!=null">
                                            <slot v-if="$store.state.orderDetails.msgFlag==true">
                                                <slot v-if="$store.state.orderDetails.dataInfos.customer_address!=null">
                                                    {{$store.state.orderDetails.dataInfos.customer_address.address}}
                                                </slot>
                                                <slot v-if="$store.state.orderDetails.dataInfos.area_info!=null">
                                                    ,{{$store.state.orderDetails.dataInfos.area_info.name}}
                                                    <slot v-if="$store.state.orderDetails.dataInfos.area_info.district_info=null">
                                                        ,{{$store.state.orderDetails.dataInfos.area_info.district_info.name}}
                                                    </slot>
                                                </slot>
                                            </slot>
                                        </slot>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" height="30"></td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <table class="table table-bordered">
                                            <thead class="table-light">
                                                <tr>
                                                    <th class="text-center">Reference</th>
                                                    <th class="text-left">Product Name</th>
                                                    <th class="text-center">Qty</th>
                                                    <th class="text-center">Rate</th>
                                                    <th class="text-center">Price</th>
                                                    <th class="text-center">Discount</th>
                                                    <th class="text-center">Sub Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <slot v-if="$store.state.orderDetails!=null">
                                                    <slot v-if="$store.state.orderDetails.msgFlag==true">
                                                        <slot v-if="$store.state.orderDetails.dataInfos">
                                                            <slot v-for="orderDetails in $store.state.orderDetails.dataInfos.order_details">
                                                                <tr>
                                                                    <th class="text-center">{{orderDetails.productId}}</th>
                                                                    <td class="text-left">{{orderDetails.product_info.name}}</td>
                                                                    <td class="text-center">{{orderDetails.quantity}}</td>
                                                                    <td class="text-center">৳ {{orderDetails.sellRate |formatNumberWithOutDecimal}}</td>
                                                                    <td class="text-center">৳ {{orderDetails.sellPrice |formatNumberWithOutDecimal}}</td>
                                                                    <td class="text-center">৳ {{orderDetails.discount |formatNumberWithOutDecimal}}</td>
                                                                    <td class="text-center">৳ {{(orderDetails.sellPrice-orderDetails.discount)|formatNumberWithOutDecimal}}</td>
                                                                </tr>
                                                            </slot>
                                                        </slot>
                                                    </slot>
                                                </slot>
                                            </tbody>
                                            <tfoot class="table-light">
                                                <tr>
                                                    <td colspan="4" rowspan="5">
                                                        <slot v-if="$store.state.orderDetails!=null">
                                                            <slot v-if="$store.state.orderDetails.msgFlag==true">
                                                                <slot v-if="$store.state.orderDetails.dataInfos.order_notes.length>0">
                                                                    <ul>
                                                                       <slot v-for="noteInfo in $store.state.orderDetails.dataInfos.order_notes">
                                                                           <li>{{noteInfo.note}}</li>
                                                                        </slot>
                                                                    </ul>
                                                                </slot>
                                                            </slot>
                                                        </slot>
                                                    </td>
                                                    <td colspan="2"><strong>Sub Total:</strong></td>
                                                    <td>
                                                        <strong>
                                                            <slot v-if="$store.state.orderDetails!=null">
                                                                <slot v-if="$store.state.orderDetails.msgFlag==true">
                                                                    ৳ {{$store.state.orderDetails.dataInfos.price | formatNumberWithOutDecimal}}
                                                                </slot>
                                                            </slot>
                                                        </strong>
                                                     </td>
                                                </tr>
                                                <tr>
                                                    <!-- <td colspan="5"></td> -->
                                                    <td colspan="2"><strong>Product Discount:</strong></td>
                                                    <td>
                                                        <strong>
                                                                <slot v-if="$store.state.orderDetails!=null">
                                                                    <slot v-if="$store.state.orderDetails.msgFlag==true">
                                                                        ৳ {{$store.state.orderDetails.dataInfos.discount |formatNumberWithOutDecimal }}
                                                                    </slot>
                                                                </slot>
                                                        </strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <!-- <td colspan="5"></td> -->
                                                    <td colspan="2"><strong>Invoice Discount:</strong></td>
                                                    <td>
                                                        <strong>
                                                                <slot v-if="$store.state.orderDetails!=null">
                                                                    <slot v-if="$store.state.orderDetails.msgFlag==true">
                                                                        ৳ {{getInvoiceDiscount($store.state.orderDetails.dataInfos.invoiceDiscount,$store.state.orderDetails.dataInfos.promoDiscount)}}
                                                                    </slot>
                                                                </slot>
                                                        </strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <!-- <td colspan="5"></td> -->
                                                    <td colspan="2"><strong>Shipping Cost:</strong></td>
                                                    <td>
                                                        <strong>
                                                                <slot v-if="$store.state.orderDetails!=null">
                                                                    <slot v-if="$store.state.orderDetails.msgFlag==true">
                                                                        ৳ {{$store.state.orderDetails.dataInfos.deliveryCharge |formatNumberWithOutDecimal}}
                                                                    </slot>
                                                                </slot>
                                                        </strong>
                                                     </td>
                                                </tr>
                                                <tr>
                                                    <!-- <td colspan="5"></td> -->
                                                    <td colspan="2"><strong>Total:</strong></td>
                                                    <td>
                                                        <strong>
                                                                <slot v-if="$store.state.orderDetails!=null">
                                                                    <slot v-if="$store.state.orderDetails.msgFlag==true">
                                                                        ৳ {{getTotalPrice($store.state.orderDetails.dataInfos)}}
                                                                    </slot>
                                                                </slot>
                                                        </strong>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" height="50"></td>
                                </tr>
                            </tbody>
                            <tfoot class="border-top">
                                <tr>
                                    <td class="py-3" colspan="2" align="center">
                                        <slot v-if="$store.state.appInfos!=null">
                                            <strong>Phone:</strong>{{$store.state.appInfos.phone}}, 
                                            <strong>Email:</strong>{{$store.state.appInfos.email}}
                                        </slot>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--/Invoice-->
</template>

<script>

export default{
     data() {
        return {
            orderDetails:[],
            msgFlag:false,
            errMsgFlag:false,
            msg:null,
            errMsg:null,
            barcode: require('../../assets/img/barcode.png')
      }
    },
    beforeMount(){
        let orderId=this.$route.params.orderId;
      this.getCustomerInfo();
       this.getOrderDetails(orderId);
    },
   
    methods:{
    getInvoiceDiscount(invoiceDiscount,promoDiscount){
            invoiceDiscount=parseInt(invoiceDiscount);
            promoDiscount=parseInt(promoDiscount);
            return invoiceDiscount+promoDiscount;
    },
      getTotalPrice(orderDetails){
        
            let totalPrice=0;
            let totalDiscount=0;
            let price=0;
            let deliveryCharge=0;
            let productDiscount=0;
            let invoiceDiscount=0;
            let promoDiscount=0;
            let grandTotal=0;
            if(orderDetails!=null){
                price=parseInt(orderDetails.price);
                deliveryCharge=parseInt(orderDetails.deliveryCharge);
                productDiscount=parseInt(orderDetails.discount);
                invoiceDiscount=parseInt(orderDetails.invoiceDiscount);
                promoDiscount=parseInt(orderDetails.promoDiscount);
            }
            totalPrice=price+deliveryCharge;
            totalDiscount=productDiscount+invoiceDiscount+promoDiscount;
            grandTotal=totalPrice-totalDiscount;
            return grandTotal;
      },
      
      getOrderDetails(orderId){
       let customerInfo=this.$store.state.customerInfo;

        let formData=new FormData();
        formData.append("dataId",orderId);
        formData.append("phone",customerInfo.phone);
        formData.append("remember",customerInfo.remember);
        formData.append("token",customerInfo.token);
        
        this.$http.post("order/details",formData)
         .then(response=>{
            this.$store.commit("storeOrderDetails",response.data);
         })
      },
      getCustomerInfo(){
        // this.$http.get("website/customer/info")
        //   .then(response=>{
        //     if(response.data.msgFlag==true){
        //       this.$store.commit("storeCustomerInfo",response.data.customerInfo);
        //     }
        //     else{
        //       this.$router.push({name:'Home'});
        //     }
        //   })
        //   .catch(error=>{
        //     console.dir(error);
        //     this.$router.push({name:'Home'});
        //   })
        //   .finally();
      },
      
    }
}
</script>

<style>

</style>